<template>
  <div class="h-auto">
    <form
      v-if="isResponse"
      class="w-full max-w-sm mt-12 mx-auto border border-solid py-10 px-6 shadow-md rounded-lg"
    >
      <div class="pb-4">
        <span
          class="px-1 py-1 mr-1 rounded-md text-gray-500 bg-gray-200 font-semibold text-xs cursor-pointer"
          v-if="j.jobTime"
          v-for="(j, i) in getJobByMarket"
          @click.prevent="selectedData(j.id)"
          :key="i"
        >
          {{ j.occurrence + " " + j.jobTime }}
        </span>
      </div>
      <h2 class="text-gray-600 text-xl mb-1 font-medium title-font border-b-2">
        {{ formTitle }}
      </h2>
      <div class="md:flex md:items-center mb-4 pt-6">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="occurrenceType"
          >
            Repeat On
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="jobConfig.occurrence"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="occurrenceType"
            required
          >
            <option>Choose Execution</option>
            <option v-for="(job, i) of occurrenceType" :key="i" :value="job.id">
              {{ job.desc }}
            </option>
          </select>
          <DropDownIcon />
        </div>
      </div>

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="occurrenceType"
          >
            Time Frame
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="jobConfig.timeFrame"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="occurrenceType"
            required
          >
            <option>Choose Execution</option>
            <option v-for="(job, i) of occurrenceType" :key="i" :value="job.id">
              {{ job.desc }}
            </option>
          </select>
          <DropDownIcon />
        </div>
      </div>

      <validation
        :errormessage="error.occurrence.message"
        :show="error.occurrence.status"
      />

      <div
        class="md:flex md:items-center mb-4"
        v-if="jobConfig.occurrence === 'W'"
      >
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="weekDay"
          >
            Week Day
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="jobConfig.weekDay"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="weekDay"
          >
            <option>Choose Code</option>
            <option
              v-for="(market, i) of weeklyDay"
              :key="i"
              :value="market.id"
            >
              {{ market.desc }}
            </option>
          </select>
          <DropDownIcon />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.weekDay.message"
        v-bind:show="error.weekDay.status"
      />

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="weekDay"
          >
            dayJobCount
          </label>
        </div>
        <div class="relative md:w-2/3">
          <input
            type="number"
            max="10"
            min="1"
            v-model="jobConfig.dayJobCount"
          />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.dayJobCount.message"
        v-bind:show="error.dayJobCount.status"
      />

      <div
        class="md:flex md:items-center mb-4"
        v-if="jobConfig.occurrence === 'M'"
      >
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="monthType"
          >
            Month Day
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="jobConfig.monthType"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="monthType"
          >
            <option>Choose Month</option>
            <option
              v-for="(month, i) of monthlyType"
              :key="i"
              :value="month.id"
            >
              {{ month.desc }}
            </option>
          </select>
          <input
            type="number"
            v-if="jobConfig.monthType === 'C'"
            max="30"
            min="1"
            v-model="jobConfig.monthDay"
          />
          <DropDownIcon />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.monthType.message"
        v-bind:show="error.monthType.status"
      />

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="inline-full-name"
          >
            Job Time
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            v-model="jobConfig.jobTime"
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="inline-full-name"
            type="datetime-local"
            step="900"
          />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.jobTime.message"
        v-bind:show="error.jobTime.status"
      />

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="jobType"
          >
            Job Type
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="jobConfig.jobType"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="jobType"
          >
            <option>Choose Job</option>
            <option v-for="(job, i) of jobs" :key="i" :value="job.id">
              {{ job.desc }}
            </option>
          </select>
          <DropDownIcon />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.jobType.message"
        v-bind:show="error.jobType.status"
      />

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="marketId"
          >
            Market Id
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="marketIdComputed"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="marketId"
          >
            <option>Choose Code</option>
            <option
              v-for="(market, i) of marketsData"
              :key="i"
              :value="market.code"
            >
              {{ market.desc }}
            </option>
          </select>
          <DropDownIcon />
        </div>
      </div>

      <validation
        v-bind:errormessage="error.marketId.message"
        v-bind:show="error.marketId.status"
      />

      <div
        class="md:flex md:items-center mb-6"
        v-if="jobConfig.jobType === 'data' && symbolList.length > 0"
      >
        <div class="md:w-1/3">
          <label
            class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
            for="symbolList"
          >
            Symbols
          </label>
        </div>
        <div class="relative md:w-2/3">
          <select
            v-model="selectedSymbols"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-500"
            id="symbolList"
            multiple
          >
            <option v-for="(market, i) of symbolList" :key="i" :value="market">
              {{ market }}
            </option>
          </select>
          <div
            class="cursor-pointer bg-red-500 hover:bg-red-700 text-white text-center mt-1 rounded"
            @click="selectedSymbols = []"
          >
            Clear Symbols
          </div>
        </div>
      </div>

      <div class="md:flex md:items-center md:justify-end">
        <div class="md:w-2/3">
          <button
            @click="handleJob"
            class="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            {{ isEdit ? "Update Job" : "Create Job" }}
          </button>
        </div>
      </div>
      <div class="md:flex md:items-center md:justify-end mt-3" v-if="false">
        <div class="md:w-2/3">
          <button
            @click="removeLAst"
            class="shadow bg-red-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            {{ Remove }}
          </button>
        </div>
      </div>
    </form>

    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import marketsData from "@/config/marketData";
import validation from "./validation.vue";
import { Config } from "../config/jobConfig";
import moment from "moment";
import { norgateApi, serverApi } from "../api/jobapi";
import { mapGetters, mapMutations } from "vuex";
import Spinner from "./spinner";
import DropDownIcon from "@/components/DropDownIcon";
const apiUrl = serverApi;

export default {
  name: "JobForm",
  components: {
    validation,
    Spinner,
    DropDownIcon,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      ...Config,
      isResponse: false,
      isEdit: false,
      formTitle: "",
      Remove: "Remove",
      jobConfig: {
        occurrence: "",
        timeFrame: "",
        weekDay: "",
        monthDay: "",
        dayJobCount: 1,
        monthType: "",
        jobType: "",
        marketId: "",
        jobTime: 0,
      },
      error: {
        occurrence: { message: "", status: false },
        weekDay: { message: "", status: false },
        dayJobCount: { message: "", status: false },
        monthType: { message: "", status: false },
        jobType: { message: "", status: false },
        marketId: { message: "", status: false },
        jobTime: { message: "", status: false },
      },
      selectedSymbols: [],
      symbolList: [],
      allSymbols: [],
      marketsData: marketsData,
    };
  },
  async mounted() {
    let marketId = this.$route.params.market;
    let marketConfig = marketsData.find((d) => d.code === marketId);
    console.log("route==>", marketId, marketConfig);
    fetch(`${apiUrl}/symbols/norgate-data/${marketConfig.listCodes}`, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((res) => res.json())
      .then((content) => {
        console.log("content==>", content);
        this.symbolList = content?.data?.data?.map((d) => d.symbol);
        this.isResponse = true;
        console.log("this.data.id", this.$props.id);
        if (this.$props.id && this.selectedJobInStore) {
          this.editJobData();
        } else {
          this.isEdit = false;
          this.formTitle = "Job Form";
        }
      });
    // const content = await rawResponse.json();
  },
  methods: {
    ...mapMutations(["SELECTED_JOB_DATA"]),
    selectedData(id) {
      console.log("id", id, this.selectedJobMarket);
      if (this.getJobByMarket) {
        if (this.$props.id !== id) {
          let jobData = this.getJobByMarket.find((d) => d.id === id);
          console.log("jobData", jobData);
          this.SELECTED_JOB_DATA(jobData);

          // this.SELECTED_JOB_DATA(jobData)
          this.$router.push(`${jobData.id}`).then((r) => console.log(r));
        }
      }
    },
    async removeLAst() {
      if (this.selectedSymbols.length > 0) {
        let c = this.selectedSymbols.join(";").concat(";");
        this.Remove = "Removing";
        const rawResponse = await fetch(
          `${apiUrl}/symbols/remove-last-five?symbols=${c}`,
          {
            method: "POST",
            headers: {
              "ngrok-skip-browser-warning": "69420",

              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        let res = await rawResponse.json();
        console.log(res);
        this.Remove = "Remove";
      }
    },
    async handleJob() {
      let isNotValid = false;
      if (this.jobConfig.dayJobCount < 0 || this.jobConfig.dayJobCount > 10) {
        this.error.dayJobCount.message = "Invalid Value";
        this.error.dayJobCount.status = true;
        isNotValid = true;
      }

      if (this.jobConfig.occurrence == "") {
        this.error.occurrence.message = "Select Execution Type";
        this.error.occurrence.status = true;
        isNotValid = true;
      }

      if (this.jobConfig.occurrence == "W") {
        if (this.jobConfig.weekDay == "") {
          this.error.weekDay.message = "select week day";
          this.error.weekDay.status = true;
          isNotValid = true;
        }
      } else if (this.jobConfig.monthType == "M") {
        if (this.jobConfig.monthType == "") {
          this.error.monthType.message = "select month day";
          this.error.monthType.status = true;
          isNotValid = true;
        }
      }
      let dateArray =
        this.jobConfig && this.jobConfig.jobTime
          ? this.jobConfig.jobTime.split("T")
          : [];
      if (dateArray.length !== 2) {
        this.error.jobTime.message = "Select job Time";
        this.error.jobTime.status = true;
        isNotValid = true;
      }
      if (this.jobConfig.jobType == "") {
        this.error.jobType.message = "select job type";
        this.error.jobType.status = true;
        isNotValid = true;
      }
      if (this.jobConfig.marketId == "") {
        this.error.marketId.message = "select market Id";
        this.error.marketId.status = true;
        isNotValid = true;
      }

      if (isNotValid) {
        return;
      } else {
        if (this.jobConfig.marketId && this.jobConfig.jobType) {
          let dataObj = JSON.parse(JSON.stringify(this.jobConfig));
          dataObj.symbolList = "";
          if (this.selectedSymbols.length) {
            dataObj.symbolList = this.selectedSymbols.join(";").concat(";");
          }
          console.log(dataObj.jobTime);
          let date = moment(dataObj.jobTime, "YYYY-MM-DD");
          // let datePart = dateArray[0]
          let timePart = dateArray[1];
          // let datePartsSplits = datePart.split('-');
          // console.log("datePartsSplits",datePartsSplits)
          // date.set("years", Number(datePartsSplits[0]));
          // date.set("months", Number(datePartsSplits[1]));
          // date.set("days", Number(datePartsSplits[2]));
          date.set("hour", Number(timePart.split(":")[0]));
          date.set("minute", Number(timePart.split(":")[1]));
          date.set("seconds", 0);
          let jobTime = date.utc().unix();
          let currentTime = moment().utc().unix();
          // console.log("jobTime",date.format("YYYY-MM-DD"))
          // console.log("currentTime",moment().format("YYYY-MM-DD"))
          // console.log("currentTime",currentTime)
          // console.log("jobTime",jobTime)
          // console.log("jobTime < currentTime",jobTime < currentTime)
          if (jobTime < currentTime) {
            this.error.jobTime.message = "Select Future Date";
            this.error.jobTime.status = true;
            return;
          }
          console.log("jobTime", jobTime, date);
          dataObj.jobTime = jobTime;

          // console.log(moment().utcOffset() / 60);

          // 2022-09-13T22:30:00Z

          // console.log(new Date().getTimezoneOffset())
          if (this.isEdit) {
            dataObj.jobId = this.$props.id;
          }
          let url = this.isEdit
            ? `/admin/updateCustomJobs`
            : `/admin/saveCustomJobs`;
          const rawResponse = await fetch(`${apiUrl}${url}`, {
            method: "POST",
            headers: {
              "ngrok-skip-browser-warning": "true",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataObj),
          });
          let res = await rawResponse.json();
          if (!res.isError) {
            this.$router.back();
          }
        }
      }
    },
    editJobData() {
      this.formTitle = "Edit Job Form";
      this.isEdit = true;
      console.log("map data", JSON.stringify(this.selectedJobInStore));
      this.jobConfig.jobType = this.selectedJobInStore.jobType;
      this.jobConfig.timeFrame = this.selectedJobInStore.timeFrame;
      this.jobConfig.monthType = this.selectedJobInStore.monthType;
      this.jobConfig.monthDay = this.selectedJobInStore.monthDay;
      this.jobConfig.weekDay = this.selectedJobInStore.weekDay;
      this.jobConfig.occurrence = this.selectedJobInStore.occurrence;
      this.jobConfig.dayJobCount = this.selectedJobInStore.dayJobCount;
      this.marketIdComputed = this.selectedJobInStore.marketId;
      let formatDate = moment(this.selectedJobInStore.jobTime).format(
        "YYYY-MM-DD HH:mm"
      );
      console.log("this.selectedJobInStore.jobTime", formatDate);
      this.jobConfig.jobTime = formatDate;
      if (this.selectedJobInStore.symbolList) {
        this.selectedSymbols = this.selectedJobInStore.symbolList
          .split(";")
          .filter((d) => {
            if (d && d.length > 0) {
              return d;
            } else {
              return false;
            }
          });
      }
    },
  },

  computed: {
    ...mapGetters([
      "selectedJobInStore",
      "getJobByMarket",
      "selectedJobMarket",
    ]),
    marketIdComputed: {
      set(value) {
        this.jobConfig.marketId = value;
      },
      get() {
        return this.jobConfig.marketId;
      },
    },
  },
  watch: {
    selectedJobInStore(n, o) {
      console.log("selectedJobInStore", n);
      this.editJobData();
    },
    marketIdComputed(n, o) {
      this.error.marketId.status = false;
      // let marketSymbols = this.allSymbols.filter((d) => d.endsWith(n));
      // this.symbolList = marketSymbols.map((d) => {
      //   let symbolRef = d.split("~")[0];
      //   return n !== "ASX" ? symbolRef.split(".")[0] : symbolRef;
      // });
    },
    "jobConfig.jobType"() {
      this.error.jobType.status = false;
    },
    "jobConfig.occurrence"() {
      this.error.occurrence.status = false;
    },
    "jobConfig.weekDay"() {
      this.error.weekDay.status = false;
    },
    "jobConfig.monthType"() {
      this.error.monthType.status = false;
    },
    "jobConfig.marketId"() {
      this.error.marketId.status = false;
    },
    "jobConfig.jobTime"() {
      this.error.jobTime.status = false;
    },
    "jobConfig.dayJobCount"() {
      this.error.dayJobCount.status = false;
    },
    getJobByMarket(n) {
      console.log("getJobByMarket", n);
    },
  },
};
</script>

<style></style>
