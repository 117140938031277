<template>
  <div class="container mx-auto mt-8">
    <h1 class="text-2xl font-semibold mb-4">Secret Form</h1>
    <form @submit.prevent="submitForm" class="max-w-md mx-auto">
      <h3>
        Please Enter The This Secret Key ==> <b>{{ showSecretKey }}</b>
      </h3>
      <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-700"
          >Password:</label
        >
        <input
          type="password"
          id="password"
          v-model="password"
          required
          class="mt-1 p-2 block w-full border rounded-md focus:outline-none focus:border-sky-500"
        />
      </div>

      <div class="mb-4">
        <label for="secretKey" class="block text-sm font-medium text-gray-700"
          >Secret Key:</label
        >
        <input
          type="text"
          id="secretKey"
          v-model="secretKey"
          required
          class="mt-1 p-2 block w-full border rounded-md focus:outline-none focus:border-sky-500"
        />
      </div>

      <button
        type="submit"
        class="bg-sky-500 text-white p-2 rounded-md hover:bg-sky-600 focus:outline-none focus:ring focus:border-sky-300"
      >
        <span v-if="isLoading">
          <!-- Display SVG or loading indicator here -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-loader"
          >
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
          </svg>
          Submitting
        </span>
        <span v-else> Submit </span>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      secretKey: "",
      showSecretKey: "ABCDEFGHIJKLMNOPQ",
      isLoading: false,
    };
  },
  methods: {
    submitForm() {
      // Mock Data
      const validPassword = "password123";
      const validSecretKey = "ABCDEFGHIJKLMNOPQ";

      // Check if entered values match the mock data
      if (
        this.password === validPassword &&
        this.secretKey === validSecretKey
      ) {
        // Replace with your actual logic
        this.isLoading = true;
        setTimeout(() => {
          this.$router.push("/alljobs");
        }, 2000);
      } else {
        alert("Invalid password or secret key. Please try again.");
      }
    },
  },
};
</script>
