export default [
    {
        id: 2,
        selected: false,
        hide: false,
        desc: "AU",
        code: "AU",
        listCodes: "AU Equities",
        marketTime: {
            zone: "Australia/Sydney",
            startTime: 9,
            endTime: 17,
        },
    },
    {
        id: 3,
        selected: false,
        hide: false,
        desc: "AU INDICES",
        code: "AU~INDX",
        listCodes: "AU Indices",
        marketTime: {
            zone: "Australia/Sydney",
            startTime: 9,
            endTime: 17,
        },
    },
    {
        id: 5,
        selected: false,
        hide: false,
        desc: "US INDICES",
        code: "US~INDX",
        listCodes: "US Indices",
        marketTime: {
            zone: "America/New_York",
            startTime: 9,
            endTime: 17,
        },
    },
    {
        id: 4,
        selected: false,
        hide: false,
        desc: "US",
        code: "US",
        listCodes: "US Equities",
        marketTime: {
            zone: "America/New_York",
            startTime: 9,
            endTime: 17,
        },
    },
    {
        id: 6,
        selected: false,
        hide: false,
        desc: "FS Indices",
        code: "FS~INDX",
        listCodes: "Forex Spot",
        marketTime: {
            zone: "America/New_York",
            startTime: 9,
            endTime: 17,
        },
    }
];