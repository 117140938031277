<template>
  <div class="modal">
    <div class="modal-content">
      <p>Are you want to deleted.?</p>
      <div class="flex justify-center mt-4">
        <button
          @click="confirmDelete"
          class="button bg-red-500 text-white px-4 py-2 mr-2"
        >
          Yes
        </button>
        <button
          @click="closeModal"
          class="button border border-solid border-red-500 text-red-500 px-4 py-2"
        >
          No
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  methods: {
    closeModal() {
      this.$emit("modal-decision",false);
    },
    confirmDelete() {
      this.$emit("modal-decision",true);
    },
  },
};
</script>

<style scoped>
/* Add your modal styles here */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
</style>
