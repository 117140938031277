import JobForm from "./components/JobForm.vue";
import JobTable from "./components/jobTable.vue";
import DataJob from "./components/DataJobLog.vue";
import CandlesData from "./components/CandlesData.vue";
import Login from "../src/components/Login.vue";
import secretPage from "../src/components/secretPage.vue";

export default [
  {
    path: "/job/:market/:id",
    component: JobForm,
    props: (route) => {
      console.log("route.params", route.params);
      return { id: route.params.id === "new" ? null : route.params.id };
    },
  },

  { path: "/alljobs", component: JobTable },
  { path: "/datajoblog", component: DataJob },
  { path: "/login", component: Login },
  { path: "/secretPage", component: secretPage },
  { path: "/candles", component: CandlesData },
];
