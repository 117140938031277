<template>
  <div id="tableapp" class="ui container">
    <h1>Data Props</h1>
    <div class="ui segment" v-if="data.length === 0">
      <p></p>
      <div class="ui active dimmer">
        <div class="ui loader"></div>
      </div>
    </div>
    <div class="ui form"  v-if="data.length > 0">
      <div class="grouped fields">
        <label>{{filter1.label}}</label>
        <div class="field" v-for="(d,i) in filter1.data" :key="i">
          <div class="ui slider checkbox">
            <input type="radio" name="throughput" v-model="filter1.value" :checked="d.value === filter1.value" :value="d.value" >
            <label>{{d.label}}</label>
          </div>
        </div>
      </div>
    </div>
    <vuetable
        v-if="data.length > 0"
        ref="vuetable"
        :api-mode="false"
        :fields="fields"
        :per-page="perPage"
        :data-manager="dataManager"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
    >
      <div slot="actions" slot-scope="props">
        <button
            class="ui small button"
            @click="onActionClicked('view-item', props.rowData)"
        >
          <i class="zoom icon"></i>
        </button>
        <button
            class="ui small button"
            @click="onActionClicked('edit-item', props.rowData)"
        >
          <i class="edit icon"></i>
        </button>
        <button
            class="ui small button"
            @click="onActionClicked('delete-item', props.rowData)"
        >
          <i class="delete icon"></i>
        </button>
      </div>
    </vuetable>
    <div style="padding-top: 10px">
      <vuetable-pagination
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import FieldsDef from "./FieldsDef.js";
import axios from "axios";
import _ from "lodash";
import {serverApi} from "@/api/jobapi";

export default {
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      fields: FieldsDef,
      perPage: 10,
      data: [],
      filterData: [],
      activeFilter: false,
      filter1:{
        data:[
          {label:"All",value:"0"},
          {label:"Has Header",value:"1"},
          {label:"Missing Header",value:"2"}
        ],
        label:"Header Filter",
        value:"0",
      }
    };
  },
  mounted() {
    axios.get(`${serverApi}/symbols/list`).then((response) => {
      console.log("response.data.data", response);
      this.data = response.data.data;
    });
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
    "filter1.value"(n,o) {
      console.log(n,o)
      this.activeFilter = n !== "0"
      switch (n){
        case "1":
          this.filterData = _.filter(this.data,{hasHeader:true})
          this.$refs.vuetable.refresh();
          break;
        case "2":
          this.filterData = _.filter(this.data,{hasHeader:false})
          this.$refs.vuetable.refresh();
          break;
        default:
          this.$refs.vuetable.refresh();
          break;
      }
    },
  },

  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.activeFilter ? this.filterData :  this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log("pagination:", pagination);
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data);
    },
  },
}
</script>
<style>
#tableapp {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>