import VuetableFieldHandle from "vuetable-2/src/components/VuetableFieldHandle.vue";

export default [
    {
        name: VuetableFieldHandle
    },
    {
        name: "collection",
        title: '<span class="orange glyphicon glyphicon-user"></span> Name',
        sortField: "collection"
    },
    {
        name: "hasHeader",
        title: "Header Data",
        sortField: "hasHeader"
    },
    {
        name: 'header',
        title: "Daily Data",
        formatter: (value) => {
            if(Array.isArray(value)){
                let data = value.find(d => d.timeFrame === 'D')
                return data?.candleCount ? data?.candleCount : 'N/A'    
            }else{
                return "N/A"
            }
             
        }
    },
    {
        name: 'header',
        title: "Weekly Data",
        formatter: (value) => {
            if(Array.isArray(value)){
            let data = value.find(d => d.timeFrame === 'W')
            return data?.candleCount ? data?.candleCount : 'N/A'
            }else{
                return "N/A"
            }
        }
    },
    {
        name: 'header',
        title: "Monthly Data",
        formatter: (value) => {
            if(Array.isArray(value)){
            let data = value.find(d => d.timeFrame === 'M')
            return data?.candleCount ? data?.candleCount : 'N/A'
            }else{
                return "N/A"
            }
        }
    },
    {
        name: 'header',
        title: "Header Length",
        formatter: (value) =>  value.length > 0 ?  value.map(d => d.timeFrame).join(",") : "N/A"
    },
    "actions"
];
